import {EDICAO_2008} from './anais.2008'
import {EDICAO_2010} from './anais.2010'
import {EDICAO_2012} from './anais.2012'
import {EDICAO_2014} from './anais.2014'
import {EDICAO_2016} from './anais.2016'
import {EDICAO_2018} from './anais.2018'
import {EDICAO_2021} from './anais.2021'
import {FOTOS_2008} from "./fotos.2008";
import {FOTOS_2010} from "./fotos.2010";
import {FOTOS_2012} from "./fotos.2012";
import {FOTOS_2014} from "./fotos.2014";
import {FOTOS_2016} from "./fotos.2016";
import {FOTOS_2018} from "./fotos.2018";
import {FOTOS_2021} from "./fotos.2021";
import {FOTOS_2023} from "./fotos.2023";

import { i18n } from '../translations/i18n'
import { EDICAO_2023 } from './anais.2023'

export const EDICOES = {
  2008: {
    year: 2008,
    name: 'Intercorr 2008',
    date:  i18n.t("edicoes.datas.2008"),
    anais: EDICAO_2008.data,
    empty: EDICAO_2008.message,
    fotos: FOTOS_2008
  },
  2010: {
    year: 2010,
    name: 'Intercorr 2010',
    date:  i18n.t("edicoes.datas.2010"),
    anais: EDICAO_2010.data,
    empty: EDICAO_2010.message,
    fotos: FOTOS_2010
  },
  2012: {
    year: 2012,
    name: 'Intercorr 2012',
    date:  i18n.t("edicoes.datas.2012"),
    anais: EDICAO_2012.data,
    empty: EDICAO_2012.message,
    fotos: FOTOS_2012
  },
  2014: {
    year: 2014,
    name: 'Intercorr 2014',
    date:  i18n.t("edicoes.datas.2014"),
    anais: EDICAO_2014.data,
    empty: EDICAO_2014.message,
    fotos: FOTOS_2014
  },
  2016: {
    year: 2016,
    name: 'Intercorr 2016',
    date:  i18n.t("edicoes.datas.2016"),
    anais: EDICAO_2016.data,
    empty: EDICAO_2016.message,
    fotos: FOTOS_2016
  },
  2018: {
    year: 2018,
    name: 'Intercorr 2018',
    date:  i18n.t("edicoes.datas.2018"),
    anais: EDICAO_2018.data,
    empty: EDICAO_2018.message,
    fotos: FOTOS_2018
  },
  2021: {
    year: 2021,
    name: 'ICC INTERCORR WCO 2021',
    date:  i18n.t("edicoes.datas.2021"),
    anais: EDICAO_2021.data,
    empty: EDICAO_2021.message,
    fotos: FOTOS_2021
  },
  2023: {
    year: 2023,
    name: 'LatinCORR & InterCorr 2023',
    date:  i18n.t("edicoes.datas.2023"),
    anais: EDICAO_2023.data,
    empty: EDICAO_2023.message,
    fotos: FOTOS_2023
  }
}
