import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Tabs, Tab} from "react-bootstrap";
import Anais from "../components/Anais";
import Galeria from "../components/Galeria";
import { i18n } from '../translations/i18n';

const Edicao = (props) => {
  const {year, edicao} = props;
    return (
      <div data-comp-edicao>
        <div className="pre-title"/>

        <h1>{i18n.t('edicoes.tituloSingular')} / {edicao.name} • <span dangerouslySetInnerHTML={{__html: edicao.date}}/></h1>
        {edicao.anais.length ? (<div className="edicao-counter">{edicao.anais.length} {i18n.t('edicoes.trabalhos')}</div>) : ''}
        <Tabs defaultActiveKey="anais">
          <Tab eventKey="anais" title={i18n.t('edicoes.anais')}>

            {edicao.anais.length > 0 ?
              edicao.anais.map((anais, i) => {
              return <Anais key={i} anais={anais}/>
              }) : <div dangerouslySetInnerHTML={{ __html: edicao.empty}}/>
          }
          </Tab>
          {edicao.fotos.length ? (
            <Tab eventKey="fotos" title={i18n.t('edicoes.fotos')}>
              <div className="my-4">
                <Galeria photos={edicao.fotos}/>
              </div>
            </Tab>
          ) : ''}

        </Tabs>
      </div>
    );
}

// class Edicao extends Component {

//   render() {
//     const {year, edicao} = this.props;
//     return (
//       <div data-comp-edicao>
//         <div className="pre-title"/>
//         <h1>Edição / {edicao.name} • {edicao.date}</h1>
//         {edicao.anais.length ? (<div className="edicao-counter">{edicao.anais.length} trabalhos</div>) : ''}
//         <Tabs defaultActiveKey="anais">
//           <Tab eventKey="anais" title="Anais">

//             {edicao.anais.length > 0 ?
//               edicao.anais.map((anais, i) => {
//               return <Anais key={i} anais={anais}/>
//               }) : <div dangerouslySetInnerHTML={{ __html: edicao.empty}}/>
//           }
//           </Tab>
//           {edicao.fotos.length ? (
//             <Tab eventKey="fotos" title="Fotos">
//               <div className="my-4">
//                 <Galeria photos={edicao.fotos}/>
//               </div>
//             </Tab>
//           ) : ''}

//         </Tabs>
//       </div>
//     );
//   }
// }

function mapStateToProps(state, ownProps) {
  return {
    year: ownProps.match.params.year,
    edicao: state.edicoes[ownProps.match.params.year]
  };
}

export default connect(
  mapStateToProps,
)(Edicao);
