export const EDICAO_2023 = {
    "data": [
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-19928.pdf",
            "name": "Corrosion Mitigation Properties Of Xanthone Derivatives As Novel And Efficient Corrosion Inhibitors For P110 Steel In Acidizing Fluid: Experimental And Theoretical Studies",
            "authors": "Ambrish Singh"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20299.pdf",
            "name": "H2S Scavenger Laboratory Methodology, Performance Data, and the Development of Combination Products",
            "authors": "Jody Hoshowski, André L. Saraceno Meliande, Davi Alves, Rolando Perez Pineiro"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20321.pdf",
            "name": "Lessons to be Learned from RBI Analysis Performed in 274 Storage Tanks",
            "authors": "Marcelo de O. Siqueira, Irwin G. Alves, Antony P. Britto, Claudio de B. Garcia"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20328.pdf",
            "name": "Maximizando a eficiência da inspeção em dutos terrestres: inspeção de dutos usando o método combinado CIPS e DCVG.",
            "authors": "Marcus F. Nunes, Valéria P. Martins"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20365.pdf",
            "name": "PAE GSJ | MOC - Opex Pipeline Optimization",
            "authors": "Cabrejas, Camila, Guzman, Abel Matías, Lamas, Facundo Nahuel, Moreno, Jonathan, Soto, Andrés Martín"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20366.pdf",
            "name": "PAE GSJ | External Corrosion Case Study",
            "authors": "Guzman, Abel Matías, Lamas, Facundo Nahuel, Soto, Andrés Martín, Vasquez Matías André"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20367.pdf",
            "name": "PAE GSJ | Pipeline Cleaning And Conditioning Program Case Study",
            "authors": "Arruas, Miguel Alejandro, Guzman, Abel Matias, Lamas, Facundo Nahuel, Soto, Andrés Martín"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20390.pdf",
            "name": "Zinc Galvanized Steel's Behavior After Accelerated Tests that Used High Chloride Ions Concentration",
            "authors": "Egnalda Pereira da Silva Pimenta, Arlindo Lopes, Marinete de Souza"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20394.pdf",
            "name": "TEOS silane film modified with SBA-15 loaded with cocoa husk extract as pretreatment of ASTM 1008 steel.",
            "authors": "Iago M. F. C. R. e Silva, Lhaira S. Barreto, Miriam S. Tokumoto, Vera R. Capeloss"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20373.pdf",
            "name": "Monitoring Corrosion and Erosion for Subsea and Dry Applications Using High Accuracy Ultrasonics",
            "authors": "Kjell Wold, Øystein Balzersen"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20397.pdf",
            "name": "Catching the polarized potential: an analysis of the Off measurements and importance of coupons",
            "authors": "Ivano Magnifico"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20399.pdf",
            "name": "Exploring the Potential of Graphene in Real-Life Industrial Anticorrosive Coatings",
            "authors": "Xavier Raby, Rafael Dias da Silva"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20403.pdf",
            "name": "Corrosion behavior of halloysite nanotubes loaded with quaternary imidazoline based inhibitor",
            "authors": "Mayara A. da T. Silva, Jean V. Ferrari, Idalina V. Aoki"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20405.pdf",
            "name": "Study of protic ionic liquids as sustained corrosion inhibitors for mild steel in saline solution with acidic pH and temperature variations",
            "authors": "Caio V. P. Pascoal, Gabriele B. da Silva, Denilson S. da Silva, Francisco C. C. S. Salomão, Eduardo B. Barros, Roberta B. Vasques, Regiane S. Pinheiro, Hosiberto B. de Sant'Ana, Walney S. Araújo"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20406.pdf",
            "name": "Effect Of CO2 Addition In The Evaluation Of Protic Ionic Liquids As Corrosion Inhibitors",
            "authors": "Gabriele B. da Silva, Caio V. P. Pascoal, Denilson S. da Silva, Francisco C. C. S. Salomão, Eduardo B. Barros, Roberta B. Vasques, Regiane S. Pinheiro, Hosiberto B. de Sant’Ana, Walney S. Araújo"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20411.pdf",
            "name": "Investigation of The Susceptibility to Preferential Corrosion of Pipeline Welded Joints",
            "authors": "Juliana L. Cardoso, Zehbour Panossian, Bruno de B. Andrade, Marcos L. Henrique, Rodrigo da S. Marques, Thales R. Gomes, Petronio Zumpano Jr., Ilson P. Batista"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20417.pdf",
            "name": "Experimental Approach Via Linear Sweep Voltammetry For Detection Of Sigma Phase In Duplex Stainless Steels: Effect Of The Increase Of Viscosity In The Electrolytic Solution",
            "authors": "Elan G. Forteski, Rodrigo Helleis, Leonardo H. Gomes, Lucas A. Bitttencurt, Fabricio Borghi, Haroldo A. Ponte, Maria J.J.S. Ponte"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20418.pdf",
            "name": "Anticorrosive inhibitor of Propolis Extract for CA-50 Carbon Steel Reinforcement",
            "authors": "Gerusa L. Alves, Lhaira S. Barreto, Vera R. Capelossi, Vanessa F. Lins"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20424.pdf",
            "name": "Testing sodium silicate as corrosion inhibitor for construction steel",
            "authors": "M. Alejandra Frontini, Marcela Vázquez, M. Beatriz Valcarce"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20427.pdf",
            "name": "Study of the corrosion of concrete reinforced with synthetic fibers using the electrochemical noise technique",
            "authors": "Alejandro F. Nicolás, Elsa C. M. Campos, Mario F. Nicolás, José J. M. González, Jorge U. Chavarín"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20428.pdf",
            "name": "Experiencias en la gestión de la corrosión bajo aislamiento en instalaciones en zonas costeras",
            "authors": "Jhonattan Barrueta, Ludmila Tello de Meneses, Francisco Ortiz, David Castellanos, Juan Abriata"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20429.pdf",
            "name": "Evaluation of polymeric coatings for MIC problems during oil and gas transportation",
            "authors": "Mariana Pagnussat, Mónica Zapponi, Verónica Rodríguez Bauzada, Pablo Castro, Silvia Elena Rastelli, Sandra Gómez de Saravia, Marisa Vier"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20430.pdf",
            "name": "Synthesis of high solids alkyds modified with reactive diluents for encapsulation and use in self-healing coatings",
            "authors": "Felipe G. Nunes, Elber V. Bendinelli, Mark D. Soucek, Idalina V. Aoki"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20433.pdf",
            "name": "Influence of fine solids in chemical treatments in up stream operations.",
            "authors": "Francisco A. Heredia, Ramiro V. Cusicondor, Leopoldo P. Simisterra, Alexis. Villacis"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20436.pdf",
            "name": "Corrosion in multiphase-flow pipelines",
            "authors": "Carlos A. Silva, Zehbour Panossian"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20437.pdf",
            "name": "Application of Yttrium Oxide Nanoparticles Produced by the Pechini Method on the Surface of Grey Cast Iron as a Protective Method Against High Temperature Oxidation",
            "authors": "Rodrigo Helleis, Elan G. Forteski, Lucas A. Bittencourt, Leonardo H. Gomes, Everson P. Banczeke, Haroldo A. Ponte"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20440.pdf",
            "name": "30 Years Lifetime with no Maintenance Needed in Splash Zones!!!",
            "authors": "Evandro R. Martin"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20441.pdf",
            "name": "Self-healing capacity evaluation of epoxy resin containing microcapsules filled with oils of different polyunsaturated fatty acids composition",
            "authors": "S. Flores, A. Hadzich, J. Arízaga"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20445.pdf",
            "name": "Synthesis and Investigation of Sulfur/Nitrogen Doped Carbon Dots as Corrosion Inhibitors for Mild Steel in CO2 Saturated Saline Medium",
            "authors": "Carlos H. B. Queiroz, Chiara Z. Mazzari, Luis G. R. Sac, Pierre B. A. Fechine, Roberta B. Vasques, Tiago M. Freire, Walney S. Araújo"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20446.pdf",
            "name": "Improvement of silane coatings using corrosion inhibitors",
            "authors": "Alan M. Oliveira, Antonio L. A. Macedo, Antonio V. C. Braga, Dalva C. B. do Lago, Lilian F. de Senna"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20447.pdf",
            "name": "Atmospheric corrosion processes in train components in sections of industrial zones.",
            "authors": "Francisco A. Heredia-Moreno, Jesús. Porcayo-Calderón, Jorge. Cantó-Ibañez"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20464.pdf",
            "name": "Coffee leaves dried extracts as green inhibitors for carbon steel in acid medium",
            "authors": "Antonio Lucas Ancelmo Macedo, Robson F. S. Navegantes, Mariana M. Marques, Antônio Victor de Castro Braga, Dalva C. B. do Lago, Lilian F. de Senna"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20469.pdf",
            "name": "Incorporacion De Glicidoxipropiltrietoxisilano En La Protección De Acero Galvanizado Y Galvanneal Expuesto En Servicio",
            "authors": "Pablo Seré, Sandra Gómez de Saravia, Cecilia Deyá"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20477.pdf",
            "name": "Long term corrosion protection – what do we actually know?",
            "authors": "Thorslund Pedersen. Lars"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20478.pdf",
            "name": "Development of a varnish incorporated with PET to be applied in stainless steel",
            "authors": "Brunela Pereira da Silva, Paulo Henrique Siqueira Borges, Julia Pimenta Perdigão Vieira, Fernando Reis da Cunha, Pedro Ricchini Villalobos, Teresa Cristina Alves Villano Rosario, Fernando Cotting"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20484.pdf",
            "name": "Corrosión bajo tensión por Etanol en un tanque de almacenamiento",
            "authors": "Vanesa A. Casariego, Franco Cornacchiulo, Rolando Bustamante, Gerardo Chiari"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20486.pdf",
            "name": "Análisis de falla de la Torre Regeneradora de Aminas de Refinería Campana",
            "authors": "Vanesa A. Casariego"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20488.pdf",
            "name": "CP Modeling of Floating Production Structures",
            "authors": "Sami B. Abu-Zahra"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20494.pdf",
            "name": "Simulation of Stress and Strain in a U-Bend Sample",
            "authors": "Anderson Z. Habibi, Lucas V. P. C. Lima, Ivan N. Bastos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20497.pdf",
            "name": "Comparative study of the efficiency of Murumuru and Andiroba as corrosion inhibitors in carbon steel in an acid medium",
            "authors": "Gustavo Dias Soares, Laís Danielle dos Santos Teixeira, Lhaira Souza Barreto, Tácia Costa Veloso, Marco Antônio Barbosa de Oliveira, Vera Rosa Capelosso"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20499.pdf",
            "name": "Preliminary Evaluation of Carbon Steel Corrosion Processes in Pre-Salt Injection System Using the Voltammetry Technique",
            "authors": "Schwitzner R. E. V., Ponte H. A., Ponte M. J. J. S., Valt R. B. G."
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20504.pdf",
            "name": "Physical-chemical characterization of a Dystroferric Yellow Latosol to use as an electrolytic medium in a Microbiologically Induced Corrosion study (MIC).",
            "authors": "Gilcelli N. Oliveira, Luis Gustavo C. N. Santos, Lhaira S. Barreto, Tacia C. Veloso, Vera R. Capelosso"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20509.pdf",
            "name": "Corrosion resistance performance of anodized and sealed AA2198-T851 alloy with coarse intermetallic removal pretreatment",
            "authors": "Bruno N. Pereira, Rafael E. Klumpp, Maria Gabriella P. Guedes, Joel S. B. L. Cipriano, Isolda Costa"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20511.pdf",
            "name": "Validation between the evaluation of corrosion inhibitors in the laboratory using LPR and EIS with results obtained from well monitoring.",
            "authors": "Francisco. Heredia, Jorge. Cantó-Ibañez, Dulce. Ortega, Edgar. Avila, Jesus. Porcayo"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20516.pdf",
            "name": "O comportamento anticorrosivo da jarosita como pigmento em revestimentos orgânicos",
            "authors": "Bárbara M. Aroeira, Caio Van Deursen, Saulo Luiz Rezende, Fernando Cotting"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20517.pdf",
            "name": "Evaluation of Steel biocorrosion with codeposition of garlic husk particles in zinc electrodeposition",
            "authors": "Lhaira Souza Barreto, Luís Gustavo Costa Nimo Santos, Paulo Eduardo Torres Firmo, Tácia Costa Veloso, Sara Horácio Oliveira, Vera Rosa Capelosso, Glória Maria Vinhas"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20518.pdf",
            "name": "Extracto acuoso de menta como inhibidor de la corrosión del acero SAE1010",
            "authors": "Christian Byrne, Sandra Gómez de Saravia, Cecilia Deyá O. Dalessandro"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20520.pdf",
            "name": "Avaliação da propagação da corrosão por pite sob a influência de íons cloreto na Sweet Corrosion e o efeito de inibidor na corrosão em ambiente aerado",
            "authors": "Gabriela P.C. Moreira, Bárbara L. Resende, Isabela A.F. Silva, Rhuan C. Souza, Luan C. Santos, José A.C.P. Gomes, Jefferson R. Oliveira, Guillermo Vilalta-Alonso, Alysson H.S. Bueno"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20524.pdf",
            "name": "Carbon steel corrosion in concretes with different cementitious matrices: a review",
            "authors": "Mariana de A. M. Rezende, Guilherme Y. Koga"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20526.pdf",
            "name": "Low-Cr steel: searching durable and sustainable structures",
            "authors": "Rafaela F. Felipe, Mariana de A. M. Rezende, Guilherme Y. Koga"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20527.pdf",
            "name": "Use Of UV-C Radiation As An Alternative To Biocide Treatments And Its Effect On Biofilms",
            "authors": "Diogo A. Coutinho, Luiz André P. Teixeira, Vinícius Flores, Viviane de Oliveira, Walter B. C. Júnior"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20529.pdf",
            "name": "Corrosion tests of the biocide 2,2-Dibromo-3-Nitrilopropionamide BNDPA in metal alloys used for the manufacture of instrumentation piping",
            "authors": "Luan C. Santos, Alysson H.S. Bueno, Rhuan C. Souza, Gabriela P.C. Moreira, Carlos Vieira Masalla, Jefferson R. Oliveira, Guillermo Vilalta-Alonso"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20530.pdf",
            "name": "Predicting Corrosion Rate in Oil Wells Using First Principles and Machine Learning",
            "authors": "Santiago Zapata-Boada, Francisco Heredia, Klever Mera"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20532.pdf",
            "name": "Corrosividade do solo sob atenuação natural",
            "authors": "Larissa O. da Silva, Danilo de Oliveira, Ivanilda R. de Melo, Sara H. Oliveira, Magda R. S. Vieira, Severino L. Urtiga Filho"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20533.pdf",
            "name": "On the Inhibition Efficiency of Different Imidazole-Based Green Corrosion Inhibitors: Insights from Raman Spectroscopy and Electrochemical Techniques",
            "authors": "Elaine F. Silva, Julio S. Wysard, Tatiana C. Almeida, Merlin C. E. Bandeira, Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20534.pdf",
            "name": "Can Small Scale Laboratory Test Help on Corrosion Management of Flexible Pipes?",
            "authors": "Merlin Bandeira, Fabricio P. dos Santos, Felipe Assunção, Rogaciano M. Moreira and Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20536.pdf",
            "name": "New green corrosion inhibitors with high potential for use in oil production fields",
            "authors": "Jéssica N. da Cunha, Brenno D. V. Evangelista, Rogaciano M. Moreira, Michelle J. C. Rezende, Alvaro A. O. Magalhães, Tatiana C. Almeida and Eliane D'Elia"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20537.pdf",
            "name": "Study of corrosion of ABNT/AISI 1020 steel in surfactant waterflooding scenario",
            "authors": "Carlos A. A. Ribeiro, Thais B. Fagundes, Gaspar Darin Filho, Patricia H. L. S. Matai, Jean V. Ferrari"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20538.pdf",
            "name": "Study on the use of N, N'-Methylenebisacrylamide as a corrosion inhibitor of a 1020 steel",
            "authors": "Késia S. Ribeiro, Francielly M. S. Soares, Rafael A. Vasconcelos, Bruno M. de Souza, Carlos N. Elias"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20539.pdf",
            "name": "Anticorrosive Coating Made by Red Ceramic Industrial Waste",
            "authors": "David C. R. Velasco, Felipe P. D. Lopes, Carlos M. F. Vieira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20540.pdf",
            "name": "Eco Friendly Anti Corrosive Coating Using Coconut Husk Agroindustry Waste as Filler",
            "authors": "David C. R. Velasco, Felipe P. D. Lopes, Carlos M. F. Vieira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20543.pdf",
            "name": "Development of experimental devices and methodologies to study corrosion problems related to the oil and gas industry",
            "authors": "Tatiana C. Almeida, Rogaciano M. Moreira, Merlin C.E. Bandeira, Felipe R.S. Assunção, Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20545.pdf",
            "name": "Lyophilized extracts of Ristretto espresso ground coffee waste as a green additive to CuZn electrodeposition baths",
            "authors": "Arthur Henrique R. Barcelos, Robson F. S. Navegantes, Mariana M. Marques, Dalva C. B. do Lago, Lilian F. de Senna"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20547.pdf",
            "name": "Development of Machine learning methodology that include the interaction of various corrosion processes",
            "authors": "Franci D. P. Gaensly, Merlin Bandeira, Carlos E. da Silva, Adriana C. Rocha, Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20549.pdf",
            "name": "Study and comparison of anti-corrosion protection, wettability and self-cleaning of different superhydrophobic coatings obtained in AA 5052.",
            "authors": "Maria I. C. Malta, Rafael G. C. da Silva, Walter L. C. da Silva Filho, Jedaías J. da Silva, Sara H. de Oliveira, Severino L. Urtiga Filho, Magda R. S. Vieira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20550.pdf",
            "name": "Advances in the Characterization of the Oxide Layers and Corrosion Products in Metallic Materials with Raman Spectroscopy",
            "authors": "Elaine F. Silva, Tatiana C. Almeida, Rogaciano M. Moreira, Merlin C. E. Bandeira, Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20551.pdf",
            "name": "Resistência a Corrosão do Aço API 5L X80 em Solução de Glicerina e o Impacto da Temperatura nas suas Propriedades Eletroquímicas",
            "authors": "Renata G. Carvalho, Ygor T. B. Santos, Irís S. Santos, José F. A. de Andrade, Vinicius A. Barros, Gabriel V. Malgaresi, Lilian L. Guarieiro"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20553.pdf",
            "name": "SIGCO (Corrosion Management Information System): Platform for managing corrosion processes and Integrity in Oilfield facilities",
            "authors": "Giovanni A. Juzga, William L. Garcia"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20555.pdf",
            "name": "Effect of photoluminescence of nanomaterials to verify corrosion in carbon steel",
            "authors": "Daniel de C. Girão, Francisco M. Lima, Juliana S. do Nascimento, Samuel Carneiro, Janaína S. Rocha, Pierre Fechine, Francisco N.A Freire"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20556.pdf",
            "name": "Assessment Of Saline Water And Diesel Oil Wettability On Nanometric Coatings Of AA5052 Aluminum For Anticorrosive And Liquid Separation Applications In The Oil And Gas Industry.",
            "authors": "Maria I. C. Malta, Rafael G. C. da Silva, Walter L. C. da Silva Filho, Jedaías J. da Silva, Sara H. de Oliveira, Severino L. Urtiga Filho, Magda R. S. Vieira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20558.pdf",
            "name": "Strategy for the Control of Microbiologically Influenced Corrosion in Water Injection Systems",
            "authors": "Giovanni A. Juzga, Juan C. Diaz, Bibiana Ramirez, Yacqueline Jaimes, Gustavo del Rio"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20560.pdf",
            "name": "Aminophosphonic acids as corrosion inhibitors for carbon steel applied in the oil industry",
            "authors": "Larissa A. Carvalho, Victor Hugo C. A. Rego, Pedro H. Rodrigues, Jussara M. Silva, Mônica Teixeira da Silva, Ladimir J. de Carvalho, Simone Louise D. C. Brasil"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20561.pdf",
            "name": "Use of Amino phosphonic Acids as Scaling Inhibitors in synthetic produced water medium",
            "authors": "Victor Hugo C. A. Rego, Larissa Carvalho, Pedro Rodrigues, Jussara M. Silva, Mônica Teixeira da Silva, Ladimir Carvalho, Simone Louise D. C. Brasil"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20563.pdf",
            "name": "Desenvolvimento de um revestimento epóxi-silano com inibidor de corrosão do pó da casca de alho aplicado no aço-carbono SAE 1008",
            "authors": "M. O. Pessoa, J.O. Braga, B.R. Freitas, S.L.B.S. Santos, V.R.Capelossi B.Pereira da Silva e F.Cotting"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/20564.pdf",
            "name": "Estudo dos efeitos corrosivos no superaquecedor secundário em uma caldeira de biomassa",
            "authors": "Gustavo V. Oliveira, Regina P. M. Garcia, Tainá L. Da Silva"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20568.pdf",
            "name": "Novel Activated Zinc Primers For Harsh And Marine Environments",
            "authors": "Jose Fernandes, Alex Yagüe, Rosileia Mantovani, Santiago Arias, David Morton"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20585.pdf",
            "name": "Self-Healing Epoxy Coating Under Immersion in Saline Solution by Using Dual Component Microcapsules",
            "authors": "Mariel A. F. Aramayo, Idalina Vieira Aoki"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20587.pdf",
            "name": "Bonding parallel pipelines with different types of coating to improve cathodic protection systems and avoid interferences and shielding: the Brazilian approach",
            "authors": "João P. K. Gervasio"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20590.pdf",
            "name": "Modified salt spray test to evaluate zinc electroplating coating with co-deposited natural particles",
            "authors": "Gabriel A. Carrijo-Gonçalves, Idalina V. Aoki, Tácia C. Veloso, Vera R. Capeloss"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20593.pdf",
            "name": "CUI Protection According to ISO 19277",
            "authors": "Evandro R. Martin, Ingrid Vee, Jenny Manvik"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20601.pdf",
            "name": "CO2 SCC testing of steel wires used in the tensile armor of flexible pipes",
            "authors": "Pedro N. da Silva, Rolf Nyborg, Henrik Fordsmand, Shobana Landgraf, Visweswara C. Gudla, Jonas Gudme"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20645.pdf",
            "name": "Study Of The Efficiency Of The Amino Acid L-Histidine As Corrosion Inhibitor Of Carbon Steel 1018, In Aerated And De-Aerated Saline Solution Saturated With CO2.",
            "authors": "Moura, M. J. S., Pascoal, C. V. P., Araújo, W. S."
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20681.pdf",
            "name": "Evaluation of pH and oxygen control contributions to mitigate the internal corrosion in iron-ore slurry pipeline",
            "authors": "Lorena M. S. Hudson, Zehbour Panossian, Juliana L. Cardoso, Gislaine M. B. Nunes, Letícia M. S. Ribeiro, Millena F. B. Caires, Cesar H. Rufino, Phillipe S. Alvarenga"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20888.pdf",
            "name": "Electrochemically Active Biofilm On AISI 1020 Steel And Its Corrosion Potential",
            "authors": "Vitor Silva Liduno, Magali Christe Cammarota, Eliana Flávia Camporese Sérvulo"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20944.pdf",
            "name": "Avaliação dos efeitos do teor de silício na variação de massa e morfologia superficial da camada de óxidos de aços baixa liga oxidados em elevadas temperaturas.",
            "authors": "Guilherme M. Costa, Lioudmila A. Matlakhova"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20949.pdf",
            "name": "Impact of Feedstock Recycling on the Corrosion Resistance of Inconel 625 Processed by L-DED",
            "authors": "Juliane Ribeiro da Cruz, Jurandir Marcos Sa de Sousa, Henrique Santos Ferreira, Anselmo Thiesen Junior, Cláudia E. B Marino"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20955.pdf",
            "name": "Uso da resina éster de epóxi como agente de reparação em aplicação direta sobre defeitos em revestimentos",
            "authors": "André Koebsch, Idalina Vieira Aoki"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20966.pdf",
            "name": "Investigation of the anodic potential influence on CO2-SCC of carbon steel flexible pipes in seawater",
            "authors": "Adriana M. Barriosa, Rodrigo V. Landim, Ilson P. Baptista, Rejane T. Batistad, Javier Alejandro C. Velasco"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20972.pdf",
            "name": "Effect of chromium on corrosion resistance of oil tube steels in a carbon dioxide corrosion system",
            "authors": "William Villamizar Sa, Jose M Lizcano, Miguel Mateus B"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-20976.pdf",
            "name": "Analysis Of Multicolored Anodic Coating of Niobium As Substitute For Coins And Medals Coatings",
            "authors": "Thalles C. de Moraes, Rodney Santandrea, Ladimir J. de Carvalho"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21003.pdf",
            "name": "Investigation of the effect of anticorrosive pigments in an epoxy system for protection of NdFeB magnets",
            "authors": "Ana L. D.M.Campista, Vinícius J. G. Costa, Ladimir J. de Carvalho, Simone L. D. C. Brasil"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21007.pdf",
            "name": "Evaluation of pH and cathodic rotation in obtaining Ni-Mo metallic alloys by electroplating",
            "authors": "Giancarlo S. Dias, Ambrósio F. Almeida Neto"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21012.pdf",
            "name": "Study About the Use of Atenolol and Zr Nanoceramic Coatings on Aluminum AA2024-T3 Alloy Surfaces and Their Impact on Corrosion Protection and Painting Adhesion Performances",
            "authors": "Kimberllyn R. da S. Pereira, Jessica P. Salles, Jane Z. Ferreira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21041.pdf",
            "name": "Influência da Temperatura na Corrosão Influenciada por Microrganismos em aço carbono (AISI 1020) sob condição estática",
            "authors": "Gislaine M. B. Nunes, Cintia P. J. Rua, Juliana P. Flor, João Lucas M. S. dos Reis, Marco A. de Lima, Paloma V. dos Santos, Patrícia Leo, Vanessa Y. N. Ferrari, Flávia Maciel F. Guedes, Neusvaldo L. de Almeida"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21059.pdf",
            "name": "Avaliação da eficiência do extrato de chá branco como inibidor de corrosão natural para o aço carbono",
            "authors": "Beatriz M. Andrade, Theo L. S. Rocha, Fernando C. L. Oliveira, Alessandra R. D. Monteiro, Felipe P. de Moura"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21065.pdf",
            "name": "Desenvolvimento de um inibidor de corrosão a partir de resíduos de brócolos para HCL 1 M",
            "authors": "Ricardo C. G. Silva, Wendell F. Oliveira, Luana B. Furtado, Maria José O. C. Guimarães, Rafaela C. Nascimento, Carolina P. G. Correa"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21119.pdf",
            "name": "Tin dioxide-based photoanodes integrated into the dye sensitized solar cells structure",
            "authors": "Francisco M. Lima, Vanja F. Nunes, Juliana S. do Nascimento, Daniel de C. Girão, Janaína S. Rocha, Ana F. L. Almeida, Francisco Nivaldo Aguiar Freire"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21124.pdf",
            "name": "Characterization of In Situ Preferential Weld Corrosion in Low-Conductivity Sweet Corrosion Media for Subsea Pipelines Using SVET and Immersion Tests",
            "authors": "Juliana L. Cardoso, Zehbour Panossian, Gislaine M. B. Nunes, Bruno de B. Andrade, Marcos L. Henrique, Rodrigo da S. Marques, Petronio Zumpano Jr., Ilson P. Baptista"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21129.pdf",
            "name": "Study of KOH Concentration on Deleterious Phases Quantification in a SAF 2707 Hyper Duplex Stainless Steel Using Linear Sweep Voltammetry",
            "authors": "Marcelo T. G. de Sampaio, Anderson B. Furtado, Marcelo D. C. Ignácio, Sérgio S. M. Tavares, Juan M. Pardal, André R. Pimenta and Eduardo A. Ponzio"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21130.pdf",
            "name": "Use of impedance spectroscopy in the study of composite systems based on post-consumer (PET) powder and commercial epoxy resin",
            "authors": "Jorge F da D. Filho, Iury I. S. de Oliveira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21136.pdf",
            "name": "Film Re-Galvanizing Technology with High Metallic Zinc Content",
            "authors": "Marco F. Ramenzoni , Luis E. Urbán , Eliana V. Cunha"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21140.pdf",
            "name": "Using an Optimized Linear Sweep Voltammetry in Alkaline Medium To Quantify Deleterious Phases in A UNS S39274 Super Duplex Stainless Steel",
            "authors": "Marcelo T. G. de Sampaio, Anderson B. Furtado, Marcelo D. C. Ignácio, Sérgio S. M. Tavares, Juan M. Pardal, André R. Pimenta and Eduardo A. Ponzio"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21151.pdf",
            "name": "Comparison between the corrosion resistance and its mechanisms in the UNS S41003 and the UNS G10100 in dilute sodium sulfate with variation of pH and chlorides",
            "authors": "Adolfo K. do N. Viana, Zehbour Panossian, Tarcisio R. de Oliveira"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21153.pdf",
            "name": "Feasibility Of A Developed Electrochemical Minicell To In Situ Evaluation Of Degree Of Sensitization On Heat Affected Zone In Supermatenstic Stainless Steel Welded With Superduplex Filler Metal",
            "authors": "Anderson B. Furtado, Marcelo T. G. de Sampaio, André R. Pimenta, Sérgio S. M. Tavares, Eduardo A. Ponzio"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21172.pdf",
            "name": "An investigation of the corrosion rate of Mg alloys in Hank's solution",
            "authors": "Thiely F. da Silva, Paula L. C. de T. Cury, Célia R. Tomachuk"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21205.pdf",
            "name": "Formulation and characterization of an anticorrosive paint using pigments of Niobium Pentoxide, Zinc and Magnetite.",
            "authors": "Vinícius J. G. Costa, Ana Laura D. M. Campista, Ladimir J.de Carvalho, Simone L. D. C."
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21222.pdf",
            "name": "Atmospheric corrosion: Challenges and opportunities for the development of metallic structures resistant to environmental degradation on the coast of the State of Ceará",
            "authors": "Daniel de C. Girão, Jackson H.B da Silva, Ieda N.S. Montenegro, Francisco B. Romero"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21261.pdf",
            "name": "AISI 430 quality surface evaluation comparing electrolytic and non-electrolytic nitric acid pickling processes",
            "authors": "Bruna F Pinto, Adolfo K. do N. Viana"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/21264.pdf",
            "name": "Miniaturization of the Hard Chrome Plating Process",
            "authors": "Vanessa dos Reis, Alvaro Meneguzzi"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21267.pdf",
            "name": "Corrosion resistance of unlacquered chromium-plated steel sheets: study on the influence of pickling and chemical treatment steps of the production process",
            "authors": "Alessandro D. Oliveira, Gabriel F. Bettencourt, João L. C. Santos, Daniela F. G. Jesus, Tatiana C. Almeida"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21307.pdf",
            "name": "Evaluation of the Influence of Niobium on the Mechanical Properties, Hydrogen Permeation and Stress Corrosion Cracking of API 5L X70 Steels",
            "authors": "Matheus P. P. Gonçalves, Kioshy S. Assis, Oscar R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21308.pdf",
            "name": "DEVELOPMENT AND IMPLEMENTATION OF A REMOTE MONITORING UNIT FOR CATHODIC PROTECTION APPLICATIONS.",
            "authors": "Sergio Pinzón, Guillermo Acevedo, Jhon León, Maria Granados, Giovanni Juzga, Javier Montañez"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC-21309.pdf",
            "name": "Comparative Analysis of Physical and Chemical Aging Effects on Blistering Resistance of Viton and NBR Polymers",
            "authors": "Rodrigo R. A. Garcia, A. S. Lima, A. M. Araujo, E. P. Q. Pereira, O. R. Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21312.pdf",
            "name": "Aspectos Relacionados a Tintas de Isolamento Térmico",
            "authors": "Flávio Vieira Vasques de Sousa, Oswaldo Esteves Barcia, Joaquim Pereira Quintela, Isabel Cristina Pereira Margarit-Mattos"
        },
        {
            "url": "https://intercorr.com.br/anais/2023/LAC23-21324.pdf",
            "name": "Low Thermal Conductivity Coatings for Thermal Barrier and CUI Prevention",
            "authors": "Chris M., Thiago R., Dr. Paul B"
        }
    ],
    "message": `<p class="mt-4">Todos os trabalhos técnicos que serão apresentados após a realização do Intercorr 2020, no período de  10 a 14 de maio, serão disponibilizados nesta página.</p>
  <p>Site do evento: <a href="https://www.ampp.org/events/latincorr-intercorr-2023">https://www.ampp.org/events/latincorr-intercorr-2023</a></p>`
}