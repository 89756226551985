export const FOTOS_2023 = [
    {
        "src": "gallery\/2023\/intercorr2023_001.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_002.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_003.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_004.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_005.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_006.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_007.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_008.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_009.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_010.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_011.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_012.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_013.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_014.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_015.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_016.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_017.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_018.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_019.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_020.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_021.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_022.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_023.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_024.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_025.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_026.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_027.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_028.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_029.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_030.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_031.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_032.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_033.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_034.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_035.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_036.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_037.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_038.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_039.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_040.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_041.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_042.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_043.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_044.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_045.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_046.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_047.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_048.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_049.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_050.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_051.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_052.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_053.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_054.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_055.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_056.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_057.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_058.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_059.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_060.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_061.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_062.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_063.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_064.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_065.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_066.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_067.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_068.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_069.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_070.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_071.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_072.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_073.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_074.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_075.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_076.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_077.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_078.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_079.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_080.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_081.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_082.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_083.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_084.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_085.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_086.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_087.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_088.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_089.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_090.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_091.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_092.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_093.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_094.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_095.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_096.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_097.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_098.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_099.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_100.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_101.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_102.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_103.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_104.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_105.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_106.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_107.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_108.jpg",
        "width": 1200,
        "height": 801
    },
    {
        "src": "gallery\/2023\/intercorr2023_109.jpg",
        "width": 801,
        "height": 1200
    },
    {
        "src": "gallery\/2023\/intercorr2023_110.jpg",
        "width": 1200,
        "height": 801
    }
  ];
  