import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Button} from 'react-bootstrap';
import { i18n } from '../translations/i18n';

const CorpoEditorial = () => {
  return (
    <div data-comp-corpoeditorial>
      <div className="pre-title"/>
      <h1>{i18n.t("editorial.titulo")}</h1>

      <h3>{i18n.t("editorial.coordenacao")}</h3>

      <p className="mt-4 mb-2"><strong>Olga Baptista Ferraz</strong>
        <a className="btn btn-outline-primary btn-sm ml-2 py-0" target="_blank"
           href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4780522D4&tokenCaptchar=03AERD8XoWyMpjrdg8mFAtjsjaXPGQuoA-X6j3fRhr1oDEmE2De89w5JE-taaqXvpEMDqf7AVsp2gT0gzncspBTll9tgmBMrUD-xGzMilT9L06_e2F3Ie5FxMnKCg3Wf6nNI3osjcnZRLlhwxdgKcWtpLMr6U1yN3uXjiC_xdivRtJHdBbuyqolY-zGSQBKpL8uuewgY2PXNzETx08He3rcC3rsyn3iEuStbs5z3_IjbheezBgzoV_iDnbIxDZubH2WYXayn2joGShCwx33CKgeEus7memg-OpppqXCaQkjw0lNUvUbWUI_ozSQuoOHEmhdkM1AnXczy8URF1Y32f5NB9v8GH_3oesx0PkpMg285AeFwTTxa2k0QfIWGts-7OzQqEHjaOfNAsiFpCc8VUVd6BnTO4g4_Q6kw">
          Lattes
        </a>
      </p>

      <p className="mt-2 mb-2"><strong>Zehbour Panossian</strong>
        <a className="btn btn-outline-primary btn-sm ml-2  py-0" target="_blank"
           href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4728658A2&tokenCaptchar=03AERD8XpC-M8xoh13WPjnnSIjUN6bCWzvA11M6Uehr4hsYizIktFORv4R6TJ-1fhmem45W4MUdLlnqLXDAQw-zaI0xFWEA8llug2qirliK1ckDH5hCxfC6yaiYL5t4Xn--g1G4kB7my-p3j8VPcQHSw7td63XvpnkP-5QnFK0BsN1pghFZ-whNvMMSBHNQJKaXbOSoljqB-xfxdm3XjbOLCOn4PnN0113gJBj47XtRYvAgROdj-nUeUAkpNFEP_7NP2jRPc7GS0hfZwXm5iJhrfjkrWQYEVvuTWOaCpjjwjJ8y9-bIJgYJ7CNO6FkzCKG9WijBIx0C6l8Uxzqa2ZA1czix24-fXaBO7Rfj3QRbAI847u1LmkU1MrEVwQFXlne0BLwXaoBzxaROGJRvVj4UKI8LYnF4M6b_w">
          Lattes</a>
      </p>

      {/* <Card.Link href="https://www.abraco.org.br">Site da Editora</Card.Link> */}

      <h3 className="mt-4 mb-2">{i18n.t("editorial.organizacao")}</h3>
      <p className="mt-2 mb-2"><strong>Associação Brasileira de Corrosão – ABRACO</strong>
        <a className="btn btn-primary btn-sm ml-2" target="_blank" href="https://abraco.org.br/">
          abraco.org.br
        </a>
      </p>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(CorpoEditorial);
