const messages = {
    en: {
        translations: {

            home: {
              header: "<p>INTERCORR is the largest international corrosion event held in Brazil that brings together the technical and scientific community of universities, research institutes, companies and professionals in the corrosion area, providing a greater exchange of knowledge and experiences. With the main objective of encouraging the exchange of information and disseminating knowledge recently acquired with the studies of corrosion and its most varied forms of control and prevention, with an audience that brings together: specialists, managers, consultants, engineers, technicians, researchers and academics involved. in several sectors linked, directly or indirectly, to the fight against corrosion and its preventive measures.</p><p>INTERCORR has been standing out and becoming a reference for the development of the industrial sector, being an excellent scenario for companies from different segments to present their technologies, publicize their brand and give visibility to their businesses, expanding relationships and knowledge.</p><p>The content of the articles published here is the sole responsibility of their authors.</p>",
              subtitulo: "The content of the articles published here is the sole responsibility of their authors.",
              tituloEdicao: "Official edition websites",
              edicao: "Edition"
            },

            edicoes: {
                titulo: 'Editions',
                tituloSingular: "Edition",
                periodo: "Event frequency:",
                resPeriodo: "every two years.",
                area: "Area of expertise",
                resArea: "Corrosion",
                trabalhos: "papers",
                fotos: "Photos",
                anais: "Proceedings",
                datas: {
                    2008: "May 12<sup>nd</sup> to 16<sup>th</sup>",
                    2010: "May 24<sup>th</sup> to 18<sup>th</sup>",
                    2012: "May 14<sup>th</sup> to 18<sup>th</sup>",
                    2014: "May 19<sup>th</sup> to 23<sup>rd</sup>",
                    2016: "May 16<sup>th</sup> to 20<sup>th</sup>",
                    2018: "May 14<sup>th</sup> to 18<sup>th</sup>",
                    2021: "July 20<sup>th</sup> to 23<sup>rd</sup>",
                    2023: "November 05<sup>th</sup> to 08<sup>rd</sup>"
                }

            },

            comissao: {
                titulo: "Scientific Commission",
                comiteExecutivo: "Committee Executive",
                comiteOrganizador: "Committee Organizing Location",
                comiteTecnico: "Committee Technical-Scientific",
                membros: "Members",
                instituicao: "Institution",
                pais: "Country",
                presidente: "President"
            },

            editorial: {
                titulo: "Editorial Team",
                coordenacao: "Coordination",
                organizacao: "Organization and Eletronic Publishing"
            },

            expediente: {
                titulo: "Expedient",
                comissaoOrganizadora: "Organizing Committee",
                int: "National Institute of Technology",
                ipt: "Institute for Technological Research",
                presidente: "president",
                periodicidade: "Publication frenquency",
                resPeriodicidade: "Biennial event",
                idioma: "Language(s) that articles will be accepted",
                inglesEportugues: "Portuguese and English",
                dadosEditora: "Publisher's data",
                nome: "Name",
                endereco: "Address",
                emailEtelefone: "Email and Telephone",
                siteDaEditora: "Publisher's website"
            }

        }

    }

}

export {messages}
